import {accessToken} from '~/features/tokenHandling/accessToken';
import {store} from '~/setup/store';
import {userProfileObtained, userSessionExpired, userSessionRenewed} from '~/features/login/actions';
import {accessTokenStored, idTokenStored} from '~/features/tokenHandling/actions';
import {env} from '~/env';
import {configureMockUserManager, configureUserManager, createUserManager} from '~/features/login/login';
import {trace} from '~/setup/trace';
import {configureFetchLanguageData} from '~/features/lang/services';
import {extractLanguage} from '~/features/lang/lang';
import {getLocale} from '~/features/lang/selectors';
import {attemptInitialSignIn} from "~/setup/oauth";

export interface OAuthConfig {
    onSessionExpired: Function;
    onSessionRenewed: Function;
}

export const main = async (renderApp: Function) => {

    const fetchLanguageData = configureFetchLanguageData(store);

    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    store.subscribe(() => {
        const lang = extractLanguage(getLocale(store.getState()));
        const html = document.querySelector('html');

        if (html?.getAttribute('lang') !== lang) {
            html?.setAttribute('lang', lang);
        }
    });

    const oauthConfig = {
        onSessionExpired: () => {
            accessToken.discardAccessToken();
            accessToken.discardIdToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: (result) => {
            accessToken.saveIdToken(result.idToken);
            accessToken.saveAccessToken(result.accessToken);

            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(userProfileObtained(result.profile));
            store.dispatch(userSessionRenewed());

            fetchLanguageData(result.locale);
        },
    } as OAuthConfig;

    const userManager = env.runtimeConfig.login.mockAuthorization
        ? configureMockUserManager(oauthConfig)
        : configureUserManager(oauthConfig, createUserManager());

    try {
        await userManager.clearStaleState();
        await attemptInitialSignIn(userManager);
        renderApp();
    } catch (error) {
        trace('could not start application', error);
    }
};
