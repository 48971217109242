import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Routes
// Devices
import DevicesListContainer from './DevicesListContainer';
import { useDataInterestCleaner } from '~/features/base/hooks/useDataInterestCleaner';

export const DevicesListContainerContainerRoute = () => {
    return (
        <DevicesListContainer/>
    );
};

export const DevicesRoute = ({ match }) => {
    useDataInterestCleaner();
    // send Google Analytics Event
    return (
        <Switch>
            <Route path={`${match.path}`} component={DevicesListContainerContainerRoute}/>
            <Redirect to={`${match.url}`}/>
        </Switch>
    );
};

export default DevicesRoute;
