import { detectFeatureToggles } from '~/setup/featureToggles';
import { networkDataByEnv } from './networkDataByEnv';

const asBool = (value: string | undefined): boolean => value === 'true';

export const dynamicEnvironmentConfig = {
    id: import.meta.env.VITE_ID,
    region: 'EU',
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_BE_AUTHENTICATION_SERVICE,
        DEVICE_MANAGEMENT_SERVICE: import.meta.env.VITE_BE_DEVICE_MANAGEMENT_SERVICE,
        DEVICE_SYSTEM_STATE_SERVICE: import.meta.env.VITE_BE_DEVICE_SYSTEM_STATE_SERVICE,
        DELIVERABLE_MANAGEMENT_SERVICE: import.meta.env.VITE_BE_DELIVERABLE_MANAGEMENT_SERVICE,
        DEVICE_LOG_DOWNLOAD_SERVICE: import.meta.env.VITE_BE_DEVICE_LOG_DOWNLOAD_SERVICE,
        LOG_SERVICE: import.meta.env.VITE_BE_LOG_SERVICE
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: import.meta.env.VITE_LOGIN_CLIENT_ID,
        mockAuthorization: detectFeatureToggles().mockedToken || asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        preventRedirect: asBool(import.meta.env.VITE_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
        oauthScope: [
            'openid',
            'profile',
            'email',
        ],
    },
    networkCodesWhiteList: networkDataByEnv[import.meta.env.MODE]?.networkCodesWhiteList,
    networkCodes: networkDataByEnv[import.meta.env.MODE]?.networkCodes

}
