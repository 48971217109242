export const networkDataByEnv = {
    test: {
        networkCodes: {
            0: { name: 'Unknown', iso2: 'xx' }, // used if network code is '000' || ''
            202: { name: 'Greece', iso2: 'gr' },
            204: { name: 'Netherlands', iso2: 'nl' },
            206: { name: 'Belgium', iso2: 'be' },
            208: { name: 'France', iso2: 'fr' },
            212: { name: 'Monaco', iso2: 'mc' },
            213: { name: 'Andorra', iso2: 'ad' },
            214: { name: 'Spain', iso2: 'es' },
            216: { name: 'Hungary', iso2: 'hu' },
            218: { name: 'Bosnia and Herzegovina', iso2: 'ba' },
            219: { name: 'Croatia', iso2: 'hr' },
            220: { name: 'Serbia', iso2: 'rs' },
            222: { name: 'Italy', iso2: 'it' },
            225: { name: 'Vatican City State', iso2: 'xx' },
            226: { name: 'Romania', iso2: 'ro' },
            228: { name: 'Switzerland', iso2: 'ch' },
            230: { name: 'Czech Republic', iso2: 'cz' },
            231: { name: 'Slovakia', iso2: 'sk' },
            232: { name: 'Austria', iso2: 'at' },
            234: { name: 'United Kingdom', iso2: 'gb' },
            235: { name: 'United Kingdom', iso2: 'gb' }, // Duplicate entry
            238: { name: 'Denmark', iso2: 'dk' },
            240: { name: 'Sweden', iso2: 'se' },
            242: { name: 'Norway', iso2: 'no' },
            244: { name: 'Finland', iso2: 'fi' },
            246: { name: 'Lithuania', iso2: 'lt' },
            247: { name: 'Latvia', iso2: 'lv' },
            248: { name: 'Estonia', iso2: 'ee' },
            250: { name: 'Russian Federation', iso2: 'ru' },
            255: { name: 'Ukraine', iso2: 'ua' },
            257: { name: 'Belarus', iso2: 'by' },
            259: { name: 'Moldova', iso2: 'md' },
            260: { name: 'Poland', iso2: 'pl' },
            262: { name: 'Germany', iso2: 'de' },
            266: { name: 'Gibraltar (UK)', iso2: 'gi' },
            268: { name: 'Portugal', iso2: 'pt' },
            270: { name: 'Luxembourg', iso2: 'lu' },
            272: { name: 'Ireland', iso2: 'ie' },
            274: { name: 'Iceland', iso2: 'is' },
            276: { name: 'Albania', iso2: 'al' },
            278: { name: 'Malta', iso2: 'mt' },
            280: { name: 'Cyprus', iso2: 'cy' },
            282: { name: 'Georgia', iso2: 'ge' },
            283: { name: 'Armenia', iso2: 'am' },
            284: { name: 'Bulgaria', iso2: 'bg' },
            286: { name: 'Turkey', iso2: 'tr' },
            288: { name: 'Faroe Islands (Denmark)', iso2: 'fo' },
            290: { name: 'Greenland (Denmark)', iso2: 'gl' },
            292: { name: 'San Marino', iso2: 'sm' },
            293: { name: 'Slovenia', iso2: 'si' },
            294: { name: 'Republic of Macedonia', iso2: 'mk' },
            295: { name: 'Liechtenstein', iso2: 'li' },
            297: { name: 'Montenegro', iso2: 'me' },
            450: { name: 'South Korea', iso2: 'kr' }
        },
        networkCodesWhiteList: [235, 225, 247, 219, 293, 295, 231, 280, 234, 202, 246, 268, 214, 248, 204, 226, 238, 216, 206, 228, 208, 270, 260, 272, 284, 262, 240, 230, 242, 286, 232, 244, 222, 450],

    },
    cnDev: {
        networkCodes: {
            0: { name: 'Unknown', iso2: 'xx' },
            240: { name: 'Sweden', iso2: 'se' },
            460: { name: 'China', iso2: 'cn' },
            262: { name: 'Germany', iso2: 'de' }
        },
        networkCodesWhiteList: [240, 262, 460]
    },
    development: {
        networkCodes: {
            0: { name: 'Unknown', iso2: 'xx' }, // used if network code is '000' || ''
            202: { name: 'Greece', iso2: 'gr' },
            204: { name: 'Netherlands', iso2: 'nl' },
            206: { name: 'Belgium', iso2: 'be' },
            208: { name: 'France', iso2: 'fr' },
            212: { name: 'Monaco', iso2: 'mc' },
            213: { name: 'Andorra', iso2: 'ad' },
            214: { name: 'Spain', iso2: 'es' },
            216: { name: 'Hungary', iso2: 'hu' },
            218: { name: 'Bosnia and Herzegovina', iso2: 'ba' },
            219: { name: 'Croatia', iso2: 'hr' },
            220: { name: 'Serbia', iso2: 'rs' },
            222: { name: 'Italy', iso2: 'it' },
            225: { name: 'Vatican City State', iso2: 'xx' },
            226: { name: 'Romania', iso2: 'ro' },
            228: { name: 'Switzerland', iso2: 'ch' },
            230: { name: 'Czech Republic', iso2: 'cz' },
            231: { name: 'Slovakia', iso2: 'sk' },
            232: { name: 'Austria', iso2: 'at' },
            234: { name: 'United Kingdom', iso2: 'gb' },
            235: { name: 'United Kingdom', iso2: 'gb' }, // Duplicate entry
            238: { name: 'Denmark', iso2: 'dk' },
            240: { name: 'Sweden', iso2: 'se' },
            242: { name: 'Norway', iso2: 'no' },
            244: { name: 'Finland', iso2: 'fi' },
            246: { name: 'Lithuania', iso2: 'lt' },
            247: { name: 'Latvia', iso2: 'lv' },
            248: { name: 'Estonia', iso2: 'ee' },
            250: { name: 'Russian Federation', iso2: 'ru' },
            255: { name: 'Ukraine', iso2: 'ua' },
            257: { name: 'Belarus', iso2: 'by' },
            259: { name: 'Moldova', iso2: 'md' },
            260: { name: 'Poland', iso2: 'pl' },
            262: { name: 'Germany', iso2: 'de' },
            266: { name: 'Gibraltar (UK)', iso2: 'gi' },
            268: { name: 'Portugal', iso2: 'pt' },
            270: { name: 'Luxembourg', iso2: 'lu' },
            272: { name: 'Ireland', iso2: 'ie' },
            274: { name: 'Iceland', iso2: 'is' },
            276: { name: 'Albania', iso2: 'al' },
            278: { name: 'Malta', iso2: 'mt' },
            280: { name: 'Cyprus', iso2: 'cy' },
            282: { name: 'Georgia', iso2: 'ge' },
            283: { name: 'Armenia', iso2: 'am' },
            284: { name: 'Bulgaria', iso2: 'bg' },
            286: { name: 'Turkey', iso2: 'tr' },
            288: { name: 'Faroe Islands (Denmark)', iso2: 'fo' },
            290: { name: 'Greenland (Denmark)', iso2: 'gl' },
            292: { name: 'San Marino', iso2: 'sm' },
            293: { name: 'Slovenia', iso2: 'si' },
            294: { name: 'Republic of Macedonia', iso2: 'mk' },
            295: { name: 'Liechtenstein', iso2: 'li' },
            297: { name: 'Montenegro', iso2: 'me' },
            450: { name: 'South Korea', iso2: 'kr' }
        },
        networkCodesWhiteList: [235, 225, 247, 219, 293, 295, 231, 280, 234, 202, 246, 268, 214, 248, 204, 226, 238, 216, 206, 228, 208, 270, 260, 272, 284, 262, 240, 230, 242, 286, 232, 244, 222, 450],
    }
};
