import { detectFeatureToggles } from './setup/featureToggles';
import { dynamicEnvironmentConfig } from '@/env/dynamicEnvironmentConfig';

export const configureEnv = (window) => {
    const featureToggles = detectFeatureToggles(window);
    console.log('env',import.meta.env)
    return  {
        featureToggles,
        runtimeConfig: dynamicEnvironmentConfig,
    };
};

export const env = configureEnv(window, process);

