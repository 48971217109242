import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

// Routes
import {
    DELIVERABLES_MANAGEMENT_PATH,
    DEVICES_PATH,
    GROUPS_PATH,
    OVERVIEW_PATH,
} from '~/features/base/constants/routes';

import ApplicationContentWrapper from '~/features/base/components/ApplicationContentWrapper';
import SubModuleContainer from '~/features/base/components/SubModuleContainer';

// Primary routes
import OverviewRoute from '~/features/overview/components/OverviewRoute';
import DevicesRoute from '~/features/devices/components/DevicesRoute';
import GroupsRoute from '~/features/groups/components/GroupsRoute';
import DeliverablesRouteNeo from '~/features/deliverables/components/DeliverablesRoute';

export const getRoutes = ({ match }) => {
    return (
        <ApplicationContentWrapper>
            <SubModuleContainer>
                <Switch>
                    <Route path={`${match.path}${OVERVIEW_PATH}`} component={OverviewRoute}/>
                    <Route path={`${match.path}${DEVICES_PATH}`} component={DevicesRoute}/>
                    <Route path={`${match.path}${GROUPS_PATH}`} component={GroupsRoute}/>
                    <Route path={`${match.path}${DELIVERABLES_MANAGEMENT_PATH}`} component={DeliverablesRouteNeo}/>
                    <Redirect to={`${match.url}${OVERVIEW_PATH}`}/>
                </Switch>
            </SubModuleContainer>
        </ApplicationContentWrapper>
    );
};

export const routes = withRouter(getRoutes);

export default routes;
