import { env } from '~/env';
import { routeStorage } from '~/features/login/storage';
import { trace } from '~/setup/trace';

const {runtimeConfig} = env;

const saveCurrentRoute = () => {
    const initialRoute = [window.location.hash, window.location.search].join('').replace(/^#\/?/u, '');
    routeStorage.saveRoute(initialRoute);
    trace('saving initial route', initialRoute);
};

export const attemptInitialSignIn = async userManager => {
    const isFreshRedirect = window.location.href.includes('redirected');

    try {
        const user = await userManager.signinSilent();
        const initialRoute = routeStorage.getRoute();

        trace('initialRoute lookup', initialRoute);

        if (initialRoute !== null && isFreshRedirect) {
            trace(`Go to location "/${initialRoute}"`);
            window.location.replace(`#/${initialRoute}`);
        }

        routeStorage.discardRoute();
        return await Promise.resolve(user);
    } catch (error) {
        trace('oidc.signinSilent failed, trying page redirect...', error);

        if (runtimeConfig.login.preventRedirect) {
            console.warn('[feature/login] redirect prevented due to config. Error was', error);
        } else if (isFreshRedirect) {
            trace('oidc.signinSilent.error', 'redirect prevented due to supsicious signin error', error);
            routeStorage.discardRoute();
            reportErrorToSentry(error);
        } else {
            saveCurrentRoute();
            userManager.signinRedirect();
        }

        trace('auth problem?', error);
        throw new Error('Need to sign in');
    }
};
