import {Log, UserManager} from 'oidc-client-ts';
import {env} from '~/env';
import {trace} from "~/setup/trace";

export const handleLoginRedirect = () => {
    const runsInIframe = window?.parent && window.parent !== window;
    if (runsInIframe) {
        trace('oidc.handleLoginRedirect', 'signinCallback');
        // Silent redirect within an <iframe>
        Log.setLogger(console);
        Log.setLevel(Log.INFO);

        // This will propagate the received information provided via
        // query parameters to the parent frame
        new UserManager({
            authority: `${env.runtimeConfig.login.authority}`,
            client_id: `${env.runtimeConfig.login.clientId}`,
            redirect_uri: `${env.runtimeConfig.login.redirectUri}`,
            filterProtocolClaims: false,
        }).signinCallback();
    } else {
        trace('oidc.handleLoginRedirect', '#/redirected');
        window.location.replace(`${window.location.origin}#/redirected`);
    }
};