import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Routes
import { EVENTS_PATH, HOME_PATH } from '~/features/base/constants/routes';

// Overview
import HomeContainer from '~/features/overview/components/HomeContainer';
import UpdateEventsContainer from '~/features/updateEvents/components/UpdateEventsContainer';
import { useDataInterestCleaner } from '~/features/base/hooks/useDataInterestCleaner';

export const OverviewRoutes = ({ match }) => {

    // send Google Analytics Event
    useDataInterestCleaner();

    return (
        <Switch>
            <Route path={`${match.path}/${HOME_PATH}`} component={(props) => <HomeContainer {...props}/>}/>
            <Route path={`${match.path}/${EVENTS_PATH}`} component={(props) => <UpdateEventsContainer {...props}/>}/>
            <Redirect to={`${match.url}/${HOME_PATH}`}/>
        </Switch>
    );
};

export default OverviewRoutes;
