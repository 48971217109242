export const configureStorage = () => {
    let storedAccessToken;
    let storedIdToken;
    return {
        discardAccessToken: () => {
            storedAccessToken = null;
        },
        getAccessToken: () => storedAccessToken,
        hasAccessToken: () => Boolean(storedAccessToken),
        saveAccessToken: token => {
            storedAccessToken = token;
        },

        discardIdToken: () => {
            storedIdToken = null;
        },
        getIdToken: () => storedIdToken,
        hasIdToken: () => Boolean(storedIdToken),
        saveIdToken: idToken => {
            storedIdToken = idToken;
        },
    };
};

export const accessToken = configureStorage();
